import React, { Component } from 'react'

import Spinner from '../components/Spinner/Spinner'
import authContext from '../context/auth-context'
import BookingList from '../components/Bookings/BookingList/BookingList'
import BookingsChart from '../components/Bookings/BookingsChart/BookingsChart'
import BookingsControls from '../components/Bookings/BookingsControls/BookingsControls'

class BookingsPage extends Component {
    state = {
        isLoading: false,
        bookings: [],
        outputType: 'list'
    }
    

    static contextType = authContext

    componentDidMount() {
        this.fetchBookings()
    }

    fetchBookings = () => {
        this.setState({isLoading: true})
        const requestBody = {
            query: `
                query {
                    bookings {
                        _id
                        createdAt
                        event {
                            _id
                            title
                            date
                            price
                        }
                    }
                }
            `
        }
        fetch('https://backend.nightriders.us/graphql2', {
        // fetch('http://localhost:8000/graphql2', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.context.token
            }
        })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Failed')
            }
            return res.json()
        })
        .then(resData => {
            const bookings = resData.data.bookings
            this.setState({bookings: bookings})
            this.setState({isLoading: false})
        })
        .catch(err => {
            console.log(err)
        })
    }

    deleteBookingHandler = bookingId => {
        this.setState({isLoading: true})
        const requestBody = {
            query: `
                mutation CancelBooking($id: ID!) {
                    cancelBooking(bookingId: $id) {
                        _id
                        title
                    }
                }
            `,
            variables: {
                id: bookingId
            }
        }
    
        fetch('https://backend.nightriders.us/graphql2', {
        // fetch('http://localhost:8000/graphql2', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.context.token
            }
        })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Failed')
            }
            return res.json()
        })
        .then(resData => {
            this.setState(prevState => {
                const updatedBookings = prevState.bookings.filter(booking => {
                    return booking._id !== bookingId
                })
                return { bookings: updatedBookings, isLoading: false }
            })
        })
        .catch(err => {
            console.log(err)
        })

    }

    changeOutputTypeHandler = outputType => {
        if (outputType === 'list') {
            this.setState({outputType: 'list'})
        } else {
            this.setState({outputType: 'chart'})
        }
    }
   

    render() {
        let content = <Spinner />
        if (!this.state.isLoading) {
            content = (
                <React.Fragment>
                    <BookingsControls 
                        activeOutputType={this.state.outputType}
                        onChange={this.changeOutputTypeHandler}  
                    />
                    <div>
                        {this.state.outputType === 'list' ? (<BookingList bookings={this.state.bookings} onDelete={this.deleteBookingHandler} /> ) : (<BookingsChart bookings={this.state.bookings} /> )} 
                    </div>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                {content}
            </React.Fragment>
            )
    }
}

export default BookingsPage

import React, { Component } from 'react'
import AuthContext from '../context/auth-context'

import './Auth.css'

class AuthPage extends Component {

    state = {
        isLogin: true
    }

    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.emailEl = React.createRef()
        this.passwordEl = React.createRef()        
    }

    switchModeHandler = () => {
        this.setState(prevState => {
            return {isLogin: !prevState.isLogin}
        })
    }

    submitHandler = event => {
        event.preventDefault()
        const email = this.emailEl.current.value
        const password = this.passwordEl.current.value

        if(email.trim().length === 0 || password.trim().length === 0) {
            return
        }

        console.log(email, password)

        let requestBody = {
            query:`
                query Login($email: String!, $password: String!) {
                    login(email: $email, password: $password) {
                        userId
                        token
                        tokenExpiration
                    }
                }
            `,
            variables: {
                email: email,
                password: password
            }
        }

        if (!this.state.isLogin)
        requestBody = {
            query: `
                mutation CreateUser($email: String!, $password: String!) {
                    createUser(userInput: {email: $email, password: $password}) {
                        _id
                        email
                    }
                }
            `,
            variables: {
                email: email,
                password: password
            }
                
        }
        
        fetch('https://backend.nightriders.us/graphql2', {
        // fetch('http://localhost:8000/graphql2', {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if (res.status !== 200 && res.status !== 201) {
                throw new Error('Failed')
            }
            return res.json()
        })
        .then(resData => {
            if (resData.data.login.token) {
                this.context.login(
                    resData.data.login.token, 
                    resData.data.login.userId, 
                    resData.data.login.tokenExpiration)
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

    render() {
        return (

            <form className='auth-form' onSubmit={this.submitHandler}>
                <h1>Login To Book the Party !</h1>
                <div className='form-control'>
                    <label htmlFor='email'>Email</label>
                    <input type="email" id="email" ref={this.emailEl} />
                </div>
                <div className='form-control'>
                    <label htmlFor='password'>Password</label>
                    <input type="password" id="password" ref={this.passwordEl} />
                </div>
                <div className='form-actions'>
                    <button type="button" onClick={this.switchModeHandler}>Switch to {this.state.isLogin ? 'Signup' : 'Login'}</button>
                    <button type="submit">Submit</button>
                </div>
                <React.Fragment>
                <p>
                    <h1>Our Ultimate Goal is 'Your Entertainment'</h1>
                </p>
                <p>
                    <h2>
                        Having a vibrant and thriving nightlife can bring about numerous benefits to individuals and communities. Here are some of the key importance of having a night life:
                    </h2>
                </p>
                <p>
                    <h2>Social Interaction and Connection: </h2>
                    Nightlife provides opportunities for people to gather, socialize, and connect with others. It creates spaces for friends, families, and communities to come together, fostering a sense of belonging and building relationships.
                </p>
                <p>
                    <h2>Cultural Expression and Diversity: </h2>
                    Nightlife often showcases a range of cultural activities, such as live music, theater performances, art exhibits, and dance shows. It allows artists, musicians, and performers to express their creativity and share their talents, enriching the cultural fabric of a place. Nightlife also celebrates diversity by offering a platform for people from different backgrounds to come together and appreciate various forms of art and entertainment.
                </p>
                <p>
                    <h2>Economic Growth and Employment Opportunities: </h2>
                    A vibrant nightlife can significantly contribute to the local economy. Restaurants, bars, clubs, and entertainment venues generate revenue and create job opportunities in the hospitality and entertainment sectors. Additionally, a bustling nightlife scene can attract tourists and visitors, leading to increased tourism spending and economic growth.
                </p>
                <p>
                    <h2>Health and Well-being: </h2>
                    Engaging in recreational activities during the night can provide an outlet for stress relief, relaxation, and enjoyment. Nightlife offers individuals a chance to unwind after a long day, have fun, and escape the pressures of daily life. Participating in social activities and connecting with others can also positively impact mental health and overall well-being.
                </p>
                <p>
                    <h2>City Branding and Image: </h2>
                    Cities with a vibrant and diverse nightlife scene often gain a positive reputation and become more attractive to residents, visitors, and businesses. A lively nightlife contributes to a city's overall image and can enhance its identity as a cultural hub or an exciting destination. This can have long-term benefits, including increased tourism, business investments, and improved quality of life for residents.
                </p>
                <p>
                    <h2>Note:</h2>
                    However, it's important to note that striking a balance between a thriving nightlife and the well-being of residents is crucial. Local authorities and communities need to address potential issues related to noise, safety, and public order to ensure a harmonious coexistence between nightlife establishments and residents living nearby.
                </p>
                </React.Fragment>
            </form>
            
        )
    }
}

export default AuthPage

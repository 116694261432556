import React, { Component } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import AuthPage from './pages/Auth';
import MainNavigation from './components/MainNavigation';
import BookingsPage from './pages/Booking'
import EventsPage from './pages/Events'
import AuthContext from './context/auth-context';

import './App.css';
import './components/MainNavigation.css'
import Home from './components/Home/Home';


class App extends Component {
  state = {
    token: null,
    userId: null
  }

  login = (token, userId, tokenExpiration) => {
    this.setState({ token: token, userId: userId })
  }

  logout = (token, userId, tokenExpiration) => {
    this.setState({ token: null, userId: null })
  }

  render() {
  return (
    <BrowserRouter>
      <React.Fragment>
        <AuthContext.Provider 
          value={{ 
              token: this.state.token, 
              userId: this.state.userId,
              login: this.login,
              logout: this.logout
           }}>
          <MainNavigation />
          <Routes>
              {!this.state.token && <Route path="/" element={<Navigate to="/home" replace />} />}
              {!this.state.token && <Route path="/bookings" element={<Navigate to="/auth" replace />} />}
              {this.state.token && <Route path="/" element={<Navigate to="/events" replace />} />}
              {this.state.token && <Route path="/auth" element={<Navigate to="/events" replace />} />}
              {!this.state.token && <Route path="/auth" Component={AuthPage} />}
              <Route path="/events" Component={EventsPage} />
              <Route path="/home" Component={Home} />
              {this.state.token && <Route path="/bookings" Component={BookingsPage} />}
          </Routes>
        </AuthContext.Provider>
      </React.Fragment>
    </BrowserRouter>
  );
}
}

export default App;

import React from 'react';
import './TwoColumn.css';

function TwoColumn() {
  return (
    <div className="row">
      <div className="col">
        column one
      </div>
      <div className="col">
        column two
      </div>
    </div>
  );
}

export default TwoColumn;
import React from 'react';
import Hero from './Hero';
import Slider from '../Slider/Slider';
import TwoColumn from '../ColumnLayouts/TwoColumn';
import Footer from './Footer';

import './Home.css';
import Hero2 from '../Hero2/Hero2';

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Slider />
      <TwoColumn />
      <TwoColumn />
      <TwoColumn />
      <Hero2 />
      <Footer />
    </div>
  );
}

export default Home;
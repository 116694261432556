import React, { useState, useEffect } from 'react';
import ElectrifiedNet from './ElectrifiedNet';

import '@fortawesome/fontawesome-free/css/all.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import musicFile from './meaw-beat.wav';

function Hero() {
  const [isLoading, setIsLoading] = useState(true);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);
  const music = new Audio(musicFile);

  useEffect(() => {
    // Simulate loading process
    setTimeout(() => {
      setIsLoading(false);
    }, 3000); // 3 seconds loading time
  }, []);

  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else if (isScrollEnabled) {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading, isScrollEnabled]);

  const scrollToContent = () => {
    setIsScrollEnabled(true);
    music.play();
    const footerElement = document.querySelector('.slider');
    footerElement.scrollIntoView({ behavior: 'smooth' });
  
    const mainNav = document.querySelector('.main-navigation'); // Select the main navigation
    const mainNavLogo = document.querySelector('.main-navigation-logo'); // Select the main navigation logo
  
    if (mainNav) { // Check if the main navigation exists
      mainNav.classList.add('hide'); // Hide the main navigation
    }
  
    if (mainNavLogo) { // Check if the main navigation logo exists
      mainNavLogo.classList.add('hide'); // Hide the main navigation logo
    }
  };

  return (
    <div className="hero">
      <ElectrifiedNet />
      <div className="hero-content">
        <h1>Rainbow Princess</h1>
        <button className="hero-btn btn btn-primary" onClick={scrollToContent}><FontAwesomeIcon icon={faArrowDown} /></button>
      </div>
    </div>
  );
}

export default Hero;
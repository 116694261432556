import React, { useEffect, useRef, useState } from 'react';
import './Slider.css'

function Slider() {
  const buyTickets = () => {
    // Get the Hero2 section
    const hero2 = document.getElementById('hero2');
    // Scroll to the Hero2 section
    hero2.scrollIntoView({ behavior: 'smooth' });
  };
  const sliderRef = useRef(null);
  const [hasEnteredSlider, setHasEnteredSlider] = useState(false); // State variable to track whether the user has entered the Slider section

  useEffect(() => {
    const mainNav = document.querySelector('.main-navigation'); // Select the main navigation
    const mainNavLogo = document.querySelector('.main-navigation-logo'); // Select the main navigation logo
    const hero2 = document.querySelector('.hero2'); // Select the hero2 section

    window.onscroll = () => {
      const sliderPos = sliderRef.current.getBoundingClientRect().top; // Get the position of the Slider section relative to the viewport

      if (sliderPos <= 0) { // Check if the Slider section is at the top of the viewport or above
        setHasEnteredSlider(true); // Set hasEnteredSlider to true
        mainNav.classList.add('hide'); // Hide the main navigation
        mainNavLogo.classList.add('hide'); // Hide the main navigation logo
        document.body.style.overflow = 'hidden'; // Disable scrolling
        if (hero2) { // Check if the hero2 section exists
          hero2.style.overflow = 'hidden'; // Disable scrolling in the hero2 section
        }
      } else if (!hasEnteredSlider) { // Only show the main navigation and the main navigation logo if the user has not entered the Slider section
        mainNav.classList.remove('hide'); // Show the main navigation
        mainNavLogo.classList.remove('hide'); // Show the main navigation logo
        document.body.style.overflow = 'auto'; // Enable scrolling
        if (hero2) { // Check if the hero2 section exists
          hero2.style.overflow = 'auto'; // Enable scrolling in the hero2 section
        }
      }
    };
  }, [hasEnteredSlider]); // Add hasEnteredSlider to the dependency array

  return (
    <div>
      <div ref={sliderRef} className="slider"><button onClick={buyTickets}>Buy Tickets to the Dance Floor</button></div>
      {/* Other components */}
    </div>
  );
}

export default Slider;
import React from "react"

import './Spinner.css'

const spinner = () => {
    <div className="spinner">
        <div class="lds-dual-ring"></div>
    </div>    
}

export default spinner
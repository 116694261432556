import React from 'react';

function Footer() {
  return (
    <div className="footer">
      footer
    </div>
  );
}

export default Footer;
import React from "react";

import { Bar as BarChart } from 'react-chartjs'

const BOOKING_BUCKETS = {
    Cheap : {
        min: 0,
        max: 1160
    },
    Normal :  {
        min: 1160,
        max: 5020
    },
    Expensive :  {
        min: 5020,
        max: 150000
    }
}

const bookingsChart = props => {
    const chartData = {labels: [], datasets: []}
    let values = []
    for (const bucket in BOOKING_BUCKETS) {
        const filteredBookingsCount = props.bookings.reduce((prev, current) => {
            if (
                current.event.price > BOOKING_BUCKETS[bucket].min &&
                current.event.price < BOOKING_BUCKETS[bucket].max 
                ) {
                return prev + 1
            } else {
                return prev
            }
        }, 0)
        values.push(filteredBookingsCount)
        chartData.labels.push(bucket)
        chartData.datasets.push({
                fillColor: "rgba(220,220,220,0.5)",
                strokeColor: "rgba(220,220,220,0.8)",
                highlightFill: "rgba(220,220,220,0.75)",
                highlightStroke: "rgba(220,220,220,1)",
                data: values
        })
        values = [...values]
        values[values.length -1] = 0
    }
    
    return (
    <div style={{textAlign: 'center'}}>
        <BarChart data={chartData} />
    </div>
    )
}

export default bookingsChart
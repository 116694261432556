import React from 'react';
import './Hero2.css'; // Assuming you have a CSS file for styling

const Hero2 = () => {
    const reloadPage = () => {
        window.location.reload();
    };
    return (
        <section id="hero2" className="hero2">
            <div className="hero2-content">
                <h1>Welcome to Our DJ Event</h1>
                <p>This is where you can get the pass for the event. Pay later.</p>
                <button onClick={reloadPage}>Learn More</button>
            </div>
        </section>
    );
};

export default Hero2;
import React, { useEffect, useRef } from 'react';

function ElectrifiedNet() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;

    // Function to resize the canvas
    const resizeCanvas = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
    };
  
      // Resize the canvas when the window size changes
    window.addEventListener('resize', resizeCanvas);
  
      // Resize the canvas initially
    resizeCanvas();

    // Function to generate a random color
    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    // Function to draw the net
    const drawNet = () => {
      context.clearRect(0, 0, width, height); // Clear the canvas

      for(let i = 0; i < width; i += 20) {
        for(let j = 0; j < height; j += 20) {
          context.beginPath();
          context.moveTo(i, j);
          context.lineTo(i + 20, j);
          context.lineTo(i, j + 20);
          
          // Create gradient
          const grd = context.createLinearGradient(i, j, i + 20, j + 20);
          grd.addColorStop(0, getRandomColor() + '00'); // Start color with 0 opacity
          grd.addColorStop(Math.random(), getRandomColor() + '88'); // Middle color with random opacity
          grd.addColorStop(1, getRandomColor() + 'FF'); // End color with full opacity

          context.strokeStyle = grd; // Set the color of the line
          context.stroke();
        }
      }
    };

    // Animation loop
    const animate = () => {
      drawNet();
      requestAnimationFrame(animate);
    };

    animate(); // Start the animation
    return () => window.removeEventListener('resize', resizeCanvas);
}, []);

return <canvas ref={canvasRef} />;
}

export default ElectrifiedNet;